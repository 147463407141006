import {mapGetters, mapState} from 'vuex'

export default {
  name: 'StoredCards',
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      brands: (state) => state.brands.brands
    }),
    ...mapGetters('storedCards', ['getCards', 'getLocation']),
    getStoredCardsList () {
      let storedCards = {}

      if (this.getCards) {
        const locations = this.currentUser ? this.currentUser.extension_attributes.locations_data : []

        this.getCards.forEach(card => {
          let active = card.extension_attributes && card.extension_attributes.brand && card.extension_attributes.brand.is_active

          if (!active) return

          let getLocationBrand = (id) => {
            let result = this.brands.find(x => x.id === id)
            return result.parent_id || result.id
          }

          let hasPermission = locations.find(location => getLocationBrand(location.brand_id) === card.extension_attributes.brand.id && location.status === true)

          if (!hasPermission) return

          if (!storedCards.hasOwnProperty(card.extension_attributes.brand.code)) {
            storedCards[card.extension_attributes.brand.code] = {
              name: card.extension_attributes.brand.name,
              brandId: card.extension_attributes.brand.id,
              brandCode: card.extension_attributes.brand.code,
              locationId: card.location_id,
              storedCards: []
            }
          }

          storedCards[card.extension_attributes.brand.code]['storedCards'].push({
            id: card.id,
            type: card.cc_type,
            number: card.cc_number,
            expDate: card.cc_expiry_date,
            locationId: card.location_id,
            isDefault: card.is_default,
            location: card.location,
            expired: card.expired
          })
        })
      }

      return storedCards
    }
  }
}
