<template>
  <component
    class="relative whitespace-no-wrap slow-color-change group inline-block outline-none underline-none hover:bg-primary2 hover:border-primary2  rounded-0 bg-transparent border-3 hover:text-white px-5 py-1 focus:outline-none uppercase font-normal leading-none"
    :is="link ? 'router-link' : 'button'" :class="[`border-${color}`, `text-${color}`, { 'loading': loading, 'pointer-events-none opacity-50': disabled }]"
    :type="link ? '' : type"
    :to="localizedRoute(link)"
    :disabled="disabled"
  >
    <span :class="`${(loading) ? 'hidden' : ''} text-${inverseColor}`" class="text-h5">
      <slot>
        Button
      </slot>
    </span>
    <loader :class="`text-${color} group-hover:text-white`" v-if="loading" />
  </component>
</template>
<script>
import focusClean from 'theme/components/theme/directives/focusClean'
import Loader from 'theme/components/theme/Loader'

export default {
  name: 'ButtonOutline',
  directives: {
    focusClean
  },
  mounted () {},
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    inverseColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Loader
  }
}
</script>
