<template>
  <div>
    <div class="relative">
      <input
        class="m0 no-outline"
        type="checkbox"
        :id="id"
        :checked="value"
        @keyup.enter="$emit('click')"
        @click="$emit('click')"
        @blur="$emit('blur')"
        @change="$emit('change')"
        :disabled="disabled"
      >
      <label
        class="pl-xl label uppercase text-black text-h5 tracking-lg leading-sm whitespace-no-wrap cursor-pointer select-none"
        :for="id"
      >
        <slot />
      </label>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
    <template v-if="validation">
      <span
        class="block cl-error h6"
        v-if="validation.condition"
        data-testid="errorMessage"
      >
        {{ validation.text }}
      </span>
    </template>
  </div>
</template>

<script>
import ValidationMessages from 'theme/components/core/blocks/Form/ValidationMessages'

export default {
  name: 'BaseCheckbox',
  components: {ValidationMessages},
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    validation: {
      type: Object,
      required: false,
      default: () => { }
    },
    validations: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    &:before {
      @apply bg-grey-6;
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .25);
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  input {
    display: none;
    position: absolute;
    top: 3px;
    left: 0;
    opacity: 0;

    &:checked + label {
      &:before {
        @apply bg-brown-2;
      }

      &:after {
        @apply text-white absolute;
        content:'✓';
        font-family: "SSGizmo";
        font-size: 20px;
        line-height: 18px;
        top: 5px;
        left: -1px;
      }
    }

    &:focus {
      + label {
        &:before {
          @apply bg-brown-2;
        }
      }
    }

    &:disabled + label {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
      &:hover,
      &:focus {
        &:before {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
