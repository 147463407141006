import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const getProductQtyIncrement = (product) => {
  const configIncrement = config.storeViews[currentStoreView().storeCode].default_qty_increment || 1

  if (product.stock && product.stock.enable_qty_increments) {
    return product.stock.qty_increments || configIncrement
  }

  return configIncrement
}
